import React from "react";
import { useGetCategoriesQuery } from "../../products/productSlice";

import "./ProductsCategory.css";
import { productData } from "../../../pages/product-detail/mockData";

import { featuredDB } from "../../../rki-data/category";
import { Link } from "react-router-dom";

function ProductsCategory() {
  const { data, isLoading, error } = useGetCategoriesQuery();

  return (
    <>
      <section className="productsCategorySec">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="fisherman-content mb-3">
                <h3>Featured Categories</h3>
              </div>
            </div>

            {isLoading && <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>}
            {error && <h4 style={{ textAlign: "center", color: "red" }}>Server Error</h4>}

            {data?.slice(0, 8).map((item, i) => {
              return <div key={item._id} className="col-lg-3 col-md-6 col-sm-12 mb-3">
                <div className="productsCategoryItem">
                  <div className="categoryHeader">
                    <h5 className="title">{item.name}</h5>
                    <Link to={`/product/category/${item._id}`}>view all </Link>
                  </div>
                  <div className="categoryBody">
                    <div className="singleProducts singleProducts-2">
                      <Link className="single-link" to={`/product/category/${item._id}`} >
                        {
                          item?.url ? <img src={productData.data[1].bigUrl} alt="Product" /> : <img src={featuredDB[i]?.url} alt="Product" />
                        }


                        <p> Flower seeds</p>
                        <span className="sale">Sale</span>
                      </Link>
                    </div>
                    {/* <div className="singleProducts">
                      <a href="/products">
                        <img src={productData.data[2].bigUrl} alt="Product" />
                        <p> Flower seeds</p>
                      </a>
                    </div> */}
                    {/* <div className="singleProducts">
                      <a href="/products">
                        <img src={productData.data[3].bigUrl} alt="Product" />
                        <p> Flower seeds</p>
                      </a>
                    </div> */}
                    {/* <div className="singleProducts">
                      <a href="/products">
                        <img src={productData.data[1].bigUrl} alt="Product" />
                        <p> Flower seeds</p>
                      </a>
                    </div> */}
                  </div>
                </div>
              </div>
            })}
          </div>
        </div>
      </section>
    </>
  );
}

export default ProductsCategory;

