import React, { useState } from "react";
import fertilizer3 from "../../../../assets/img/products/3.jpg";
import Rating from "../../../../shared/rating/Rating";

import { FiSearch } from "react-icons/fi";
import { GrAdd } from "react-icons/gr";
import { BsFillCartFill } from "react-icons/bs";
import { BiLoaderAlt } from "react-icons/bi";

import { Link } from "react-router-dom";
import {
  setTodayDeal,
  useGetProductDetailQuery,
} from "../../../products/productSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { featuredDB } from "../../../../rki-data/category";
import Slider from "react-slick";

function TodayDealsProduct({ data, isLoading, error, handleShow }) {
  const curr = window.localStorage.getItem('currencySym')
  const currencySymbol = curr ? curr : 'ZK'

  const dispach = useDispatch()

  useEffect(() => {
    dispach(setTodayDeal(data))
  }, [data])

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  // counter timner

  const COUNTDOWN_TARGET = new Date('2023-09-14T23:59:59')

  const getTimeLeft = () => {
    const totalTimeLeft = COUNTDOWN_TARGET - new Date();
    const days = Math.floor(totalTimeLeft / (1000 * 60 * 60 * 24));
    const hour = Math.floor(totalTimeLeft / (1000 * 60 * 60) % 24);
    const minute = Math.floor(totalTimeLeft / (1000 * 60) % 60);
    const second = Math.floor(totalTimeLeft / (1000) % 60);
    return { days, hour, minute, second }
  }

  const [timeLeft, setTimeLeft] = useState(() => getTimeLeft())

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(getTimeLeft())
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [])
  return (
    <>

      <div className="col-lg-3">
        <div className="pd-block">
          <p>AB: Only today you can buy a camera with - 20% discount</p>
          <p className="byu-p">Buy a camera of a well-known brand with 20% discount during this period.</p>
          <p className="byu-p">Buying promotional models of cameras or Canon lenses, you get a free one-year subscription to a plan for photographers from Adobe Creative Cloud and one of the online courses.</p>
        </div>
        <strong>Promotions expires within:</strong>
        <div className="timer">

          <ul>
            {Object.entries(timeLeft).map((el) => {
              const label = el[0]
              const value = el[1]
              return (
                <li key={label}>
                  <span>{value}</span>
                  {/* <span className="lable">{label}</span> */}
                  {label}
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      <div className="col-lg-9">
        <Slider {...settings}>
          {isLoading ? (
            <div className="loaderIcon">
              <BiLoaderAlt />
            </div>
          ) : null}
          {data?.slice(0, 4)?.map((item) => {
            // console.log('todayDeal', item);
            // console.log('hiuhjuhk', item);
            return (
              <div className="row" key={item._id}>
                <div className="featuredInfo col-lg-12">
                  <div className="featuredFigure">
                    <div className="featuredImg-2">
                      <Link to={`/product/${item._id}`}>
                        {item?.variations[0]?.mainImage_url.url ? <img src={item?.variations[0]?.mainImage_url.url} alt="Product" /> : <img src={fertilizer3} alt="Product" />}

                      </Link>
                      <div className="quickView quickView-2">
                        <ul>
                          <li className="viewProduct">
                            <button
                              className="quick_view_btn"
                              onClick={(e) => {
                                handleShow(item._id)
                              }}
                            >
                              <FiSearch />
                            </button>
                          </li>
                          <li className="addProduct">
                            <Link to="/products">
                              <GrAdd />
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <ul className="hotList">
                      <li>
                        <Link to="/products">Sale</Link>
                      </li>
                      <li>
                        <Link to="/products">-17%</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="featuredContent">
                    {/* <h6>category</h6> */}
                    <h5>
                      <Link to={`/product/${item._id}`}>{item.name}</Link>
                    </h5>
                    <Rating />
                    <div className="rateDigit">
                      <span className="cross">{currencySymbol} {item?.[0]?.variations[0]?.mrp}</span>
                      <span className="currentPrice">{currencySymbol} {item?.[0]?.variations[0]?.sale_rate}</span>
                    </div>

                    <div className="buyNowInfo">
                      <Link className="btn btn-danger addCart" to={`/product/${item._id}`}
                      >
                        View Detail
                      </Link>
                      <Link
                        to={`/product/${item._id}`}
                        className="btn btn-primary buyNow"
                      >
                        <BsFillCartFill /> Buy Now
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          {error && (
            <div className="alertMsg mb-4" role="alert">
              {" "}
              No Data Found{" "}
            </div>
          )}


        </Slider>
      </div>



      {/* {isLoading ? (
        <div className="loaderIcon">
          <BiLoaderAlt />
        </div>
      ) : null}
      {featuredDB?.slice(0, 4)?.map((item) => {
        console.log('hiuhjuhk', item);
        return (
          <div className=" col-lg-3 col-md-6 col-sm-12" key={item._id}>
            <div className="featuredInfo">
              <div className="featuredFigure">
                <div className="featuredImg">
                  <Link to={`/product/${item._id}`}>
                    {item?.url ? <img src={item?.url} alt="Product" /> : <img src={fertilizer3} alt="Product" />}

                  </Link>
                  <div className="quickView">
                    <ul>
                      <li className="viewProduct">
                        <button
                          className="quick_view_btn"
                          onClick={(e) => {
                            handleShow(item._id)
                          }}
                        >
                          <FiSearch />
                        </button>
                      </li>
                      <li className="addProduct">
                        <Link to="/products">
                          <GrAdd />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <ul className="hotList">
                  <li>
                    <Link to="/products">Sale</Link>
                  </li>
                  <li>
                    <Link to="/products">-17%</Link>
                  </li>
                </ul>
              </div>
              <div className="featuredContent">
                <h5>
                  <Link to={`/product/${item._id}`}>{item.name}</Link>
                </h5>
                <Rating />
                <div className="rateDigit">
                  <span className="cross">{currencySymbol} {item?.[0]?.variations[0]?.mrp}</span>
                  <span className="currentPrice">{currencySymbol} {item?.[0]?.variations[0]?.sale_rate}</span>
                </div>

                <div className="buyNowInfo">
                  <Link className="btn btn-danger addCart" to={`/product/${item._id}`}
                  >
                    View Detail
                  </Link>
                  <Link
                    to={`/product/${item._id}`}
                    className="btn btn-primary buyNow"
                  >
                    <BsFillCartFill /> Buy Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        );
      })}

      {error && (
        <div className="alertMsg mb-4" role="alert">
          {" "}
          No Data Found{" "}
        </div>
      )} */}

    </>
  );
}

export default TodayDealsProduct;
